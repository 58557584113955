//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import widgetLayoutMixin from "../../../mixins/widgetLayoutMixin";
import playIcon from '@/assets/img/icons/play.svg?raw'
import CommonMedia from "@/components/common/CommonMedia";
import IconButton from "../../../components/common/buttons/IconBtn";

export default {
    name: "Main",
    mixins: [widgetLayoutMixin],
    components: {
        IconButton,
        CommonMedia,
        playIcon,
    },
    data() {
        return {
            playIco: playIcon,
            isPlaying: false
        }
    },
    methods: {
        changePlaying(flag) {
            this.isPlaying = flag;
        }
    },
    watch: {
        isPlaying: {
            handler(val) {
                if(val) {
                    const video = document.querySelector('.video-widget__video video')
                    video.load()
                    video.addEventListener('ended', function () {
                        this.isPlaying = false
                    }.bind(this))
                }
            }
        }
    }
}
