//
//
//
//
//
//
//
//
//
//
//
//

import StoreFilter from "@/components/pages/store/StoreFilter/index";
import StoreList from "@/components/pages/store/StoreList/index";
import StorePagination from "@/components/pages/store/StorePagination/index";
import categoryPageEvent from "~/assets/js/eSputnik/categoryPageEvent";

import { mapGetters } from "vuex";
import StoreFilterMinimal from "~/components/pages/store/StoreFilterMinimal/index.vue";
import StoreSubmenu from "~/components/pages/store/StoreSubmenu/index.vue";
import FeatureProductWidget from "~/widgets/store/Catalog/FeatureProducts/index.vue";
import catalogStructuredData from "~/mixins/structuredData/catalogStructuredData";

export default {
    name: "Catalog",
    components: {
        FeatureProductWidget,
        StoreSubmenu,
        StoreFilterMinimal,
        StoreFilter,
        StorePagination,
        StoreList
    },
    mixins: [ catalogStructuredData ],

    head() {
        return {
            script: [
                this.catalogStructuredData,
            ]
        };
    },
    computed: {
        ...mapGetters({
            products: "catalogStore/getProducts",
            pageH1: "dynamicPage/pageH1",
            breadcrumbsData: "dynamicPage/breadcrumbsData",
            pageStructure: "dynamicPage/getMicroPageStructure",
        }),
        isSearch() {
            return this.$route.path === "/search";
        },
        route() {
            return this.$route.fullPath;
        },
        catalogSubmenuWidget() {
            return this.pageStructure.find(w => w.name === "widget_submenu")
        },
        submenuList() {
            return  this.catalogSubmenuWidget?.data?.items ?? [];
        },
        hasCatalogSubmenu() {
            return  Boolean(this.submenuList?.length);
        }
    },

    watch: {
        route: {
            handler(newVal, oldVal) {
                if (process.browser && (JSON.stringify(newVal) !== JSON.stringify(oldVal)) && this.products.length > 0) {
                    let arr = [];
                    this.products.map((el) => {
                        el.sizes.map(size => {
                            arr.push({
                                id: size.id,
                                google_business_vertical: "retail"
                            });
                        });
                        return arr;
                    });

                    this.$gtag("event", this.isSearch ? "view_search_results" : "view_item_list", {
                        items: arr,
                    });
                }
            },
            immediate: true
        },
    },

    mounted() {
        if (process.browser) {
            categoryPageEvent({ breadcrumbs: this.breadcrumbsData, pageTitle: this.pageH1 });
        }
    }
};
