//
//
//
//
//
//
//
//
//

export default {
    name: "SubscribeAgree",
    props: {
        email: {
            type: String,
            default: ''
        }
    },
    computed: {
        message() {
            return this.$t('common.signUp.blockAgree.agreeDesc').replace("{0}", this.email);
        }
    }
}
