//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VPopup from "@/plugins/VPopup/popup";
import YeezyPhoneNumber from "@/components/popups/YeezyPopups/YeezyPhonePopup/components/YeezyPhoneNumber.vue";
import YeezyPhoneCode from "@/components/popups/YeezyPopups/YeezyPhonePopup/components/YeezyPhoneCode.vue";
import Input from "../../../common/LazyFormRow/components/Checkbox";

export default {
    name: "YeezyPhonePopup",
    components: {Input, YeezyPhoneCode, YeezyPhoneNumber, VPopup},
    props: {
        formId: {
          type: String,
          required: true
        },
        redirectUrl: {
            type: String,
            default: '/account'
        },
        needRedirect: {
            type: Boolean,
            default: true
        },
        yeezyMode:{
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isCodeSend: false,
            phoneValue: '',
            formIdPhoneStep: 'otp_verify_sms_step_one',
            formIdCodeStep: 'otp_verify_sms_step_two'
        }
    },
    methods: {
        sendPhoneCode(phone) {
            this.phoneValue = phone;
            this.isCodeSend = true;
        },
        resendCode() {
            this.phoneValue = '';
            this.isCodeSend = false;
        },
        async confirmSuccessed ({ phone, token }) {
            this.$popups.close()
            this.$emit('phoneConfirm', phone, token )
        }
    }
}
