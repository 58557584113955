//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CommonScroll from "@/components/common/CommonScroll";
export default {
    name: "StorePaginationSelect",
    components: {CommonScroll},
    props: {
        items: {
            type: Array,
            default: () => { return [] }
        },
        active: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            opened: false
        }
    },
    methods: {
        toggleList() {
            this.opened = !this.opened
        },
        closeSelect(){
            this.opened = false
        }
    }
}
