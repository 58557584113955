//
//
//
//
//
//
//
//
//

import SectionFullWidth from "~/components/common/section/SectionFullWidth";
import widgetMixin from "~/mixins/widgetMixin";
import mainLayout from "./layouts/Main";

export default {
    name: "TeaserProductNavigation",

    components: {
        SectionFullWidth,
        mainLayout
    },

    mixins: [widgetMixin],

    data() {
        return {
            innerDataCopy: JSON.parse(JSON.stringify(this.widgetInfo))
        };
    },
};
