//
//
//
//
//
//
//
//

import CommonMedia from "@/components/common/CommonMedia";
export default {
    name: "Item",
    components: {CommonMedia,},
    props: {
        item: {
            type: Object,
            required: true
        }
    },
}
