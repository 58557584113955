//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ResponsiveImage from "@/components/common/ResponsiveImage";
import CommonBtnText from "~/components/common/buttons/TextBtn";

export default {
    name: "Item",
    components: { CommonBtnText, ResponsiveImage },
    props: {
        item: {
            type: Object,
            required: true
        },
        widgetInfo: {
            type: Object,
            required: true
        },
        position: {
            type: Number,
            required: true
        },
    },
};
