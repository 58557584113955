import { appConfig } from "~/utils/config";
import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters({
            "productData": "productView/getProduct",
            "metaTags": 'dynamicPage/metaTags',
        }),

        descriptionValue() {
            let description = this.metaTags.find(el => el.name === 'description');
            return (description?.content ?? "");
        },
        productHasSizes() {
            return Boolean(this.productData?.sizes?.filter(size => size.hasOwnProperty('isAvailable') && size.isAvailable).length);
        },
        imagesFullListUrls() {
            if (this.productData?.imagesFullList && this.productData?.imagesFullList.length) {
                return this.productData?.imagesFullList.map(imageItem => {
                    const imageSizes = ImageHelper.getMediaStructure(
                        imageItem.id,
                        "jpg",
                        imageItem.title,
                        imageItem.alt,
                        ImageSizes.catalogMain,
                        60
                    );
                    imageItem.simpleUrl = imageSizes?.src?.desktop?.["1x"];
                    return imageItem;
                });
            }
            return [];
        },
        priceValidUntil() {
            let today = new Date();
            let month = today.getMonth() + 1;
            let year = today.getFullYear();
            let lastDayDate = new Date(year, month, 0);
            return `${year}-${month < 10 ? '0'+month : month}-${lastDayDate.getDate()}`
        },
        productStructuredData() {
            return {
                type: "application/ld+json",
                json: {
                    "@context": "https://schema.org/",
                    "@type": "Product",
                    "name": `${ this.productData?.displayName }`,
                    "sku": `${ this.productData?.article }`,
                    "color": `${ this.productData?.color }`,
                    "description": `${ this.descriptionValue }`,
                    "image": this.getProductHeroImagesList(),
                    "brand": {
                        "@type": "Brand",
                        "name": `adidas ${ this?.productData?.division || "" }`
                    },
                    "offers": {
                        "@type": "Offer",
                        "priceValidUntil": `${this.priceValidUntil}`,
                        "url": `${ this.productData?.url?.absolute }`,
                        "priceCurrency": `${ appConfig?.mainInfo?.currency }`,
                        "price": `${ this.productData?.price?.sale }`,
                        "seller": {"@type": "Organization", "name": "adidas"},
                        "availability": this.productHasSizes ? "https://schema.org/InStock" : "https://schema.org/OutOfStock",
                        "shippingDetails": {
                            "@type": "OfferShippingDetails",
                            "shippingDestination": {
                                "@type": "DefinedRegion",
                                "addressCountry": "UA"
                            }
                        }
                        ,
                        "hasMerchantReturnPolicy": {
                            "@type": "MerchantReturnPolicy",
                            "url": "https://www.adidas.ua/zagalni-umovi-povernennya",
                            "applicableCountry": "UA",
                            "returnPolicyCategory": "https://schema.org/MerchantReturnFiniteReturnWindow",
                            "merchantReturnDays": 60,
                            "returnMethod": "https://schema.org/ReturnByMail",
                            "returnFees": "https://schema.org/ReturnFeesCustomerResponsibility"
                        }
                    },
                }
            }
        }
    },

    methods: {
        getProductHeroImagesList() {
            return this.imagesFullListUrls && this.imagesFullListUrls.length ?
                this.imagesFullListUrls.map(item => item.simpleUrl) :
                [];
        }
    },
};
