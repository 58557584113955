//
//
//
//

import mainLayout from './layouts/Main'
import widgetMixin from "../../mixins/widgetMixin";

export default {
    name: "SubscribeBlock",
    components: { mainLayout },
    mixins: [widgetMixin],
}
