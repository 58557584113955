//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VPopup from "~/plugins/VPopup/popup";
import SecondaryButton from "@/components/common/buttons/SecondaryBtn";

export default {
    name: "YeezyCheckSize",
    components: {SecondaryButton, VPopup},
    props:{
        currentSize: {
            type: Object,
            required: true
        }
    },
    methods: {
        closePopup(){
            this.$popups.close();
        },
        submitSize(){
            this.$emit('submitSize')
            this.closePopup()
        }
    }
}
