//
//
//
//
//
//
//
//
//
//
//
//

import YeezyInfo from "../yeezyContent/Components/YeezyInfo";
import YeezySizes from "../yeezyContent/Components/YeezySizes";
import YeezyFormData from "../yeezyContent/Components/YeezyFormData";
import breakpointsListMixin from '~/mixins/breakpointsList.mixin';

export default {
    name: "YeezySidebar",
    components: {
        YeezyFormData,
        YeezySizes,
        'yeezy-info': YeezyInfo
    },
    mixins: [
        breakpointsListMixin,
    ],
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            sizeErrorMessage: '',
            hasRequest: false,
            hasRequestSize: ''
        }
    },
    computed: {
        isDesktop() {
            return this.tabletLandscapeUp;
        },
    },
    methods: {
        sizeError(message) {
            this.sizeErrorMessage = message
        },
        isAlreadyExist(size) {
            this.hasRequest = true;
            this.hasRequestSize = size;
        }
    }
}
