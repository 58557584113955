//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyFormRow from "@/components/common/LazyFormRow/index";
import CommonForm from "@/components/common/CommonForm";
import popupWithForm from "@/mixins/popupWithForm";

export default {
    name: "YeezyPhoneNumber",
    components: {CommonForm, LazyFormRow},
    mixins: [popupWithForm],
    data() {
        return {
            formCodeName: 'otp_verify_sms_step_two',
        }
    },
    computed: {
        phoneField() {
            return this.fields.find((field) => {
                return field.name === 'phoneNumber'
            })
        },
        phone() {
            return (this.phoneField?.value ?? '')
        }
    },
    methods: {
        async successSubmit(responseData) {
            this.$emit('sendCode', this.phone);
            this.$store.dispatch(`forms/loadFormByIdWithData`, {
                formId: this.formCodeName,
                data: {
                    ...this.fieldsStates.reduce((acc, formName) => {
                        acc = {
                            ...acc,
                            ...responseData[formName]
                        };
                        return acc;
                    }, {})
                }
            })
        },
        onBtnReject() {
            this.$popups.close();
        },
    }
}
