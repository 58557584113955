//
//
//
//
//
//

import mainLayout from './layouts/Main'
import widgetMixin from "@/mixins/widgetMixin";

export default {
    name: "HelpPost",
    components: { mainLayout},
    mixins: [widgetMixin],
    computed: {
        layoutName() {
            return this.layout+'Layout'
        },
    }
}
