//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import ResponsiveImage from "@/components/common/ResponsiveImage";
import CommonMedia from "../../../../../../../../components/common/CommonMedia";

export default {
    name: "YeezySlider",
    components: {
        CommonMedia,
        VueSlickCarousel,
        ResponsiveImage,
    },
    props: {
        images : {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            settings: {
                dots: true,
                variableWidth: false,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false
            },
            activeSlide: 0,
            zoomActive: false,
            isFullScreen: false,
        }
    },
    methods: {
        showNext() {
            this.$refs.carousel.next()
        },
        showPrev() {
            this.$refs.carousel.prev()
        },
    }
}
