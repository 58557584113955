//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import widgetLayoutMixin from "@/mixins/widgetLayoutMixin";

export default {
    name: "HomeSeoMain",
    mixins: [
        widgetLayoutMixin,
    ],
    computed: {
        cards() {
            return (this?.innerData?.items ?? [])
        },
    }
}
