//
//
//
//
//
//
//
//
//
//
//



import yeezyContent from "./yeezyContent/index.vue";
import yeezySidebar from "./yeezySidebar/index.vue";
import YeezyBackButton from "./yeezyContent/Components/YeezyBackButton";
import SectionContainer from "@/components/common/section/SectionContainer";

export default {
    name: "YeezyCard",
    components: {
        YeezyBackButton,
        'section-container': SectionContainer,
        'yeezy-content': yeezyContent,
        'yeezy-sidebar':yeezySidebar
    },

    props: {
        product: {
            type: Object,
            required: true
        }
    },
}
