//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import StoreFilterMinimal from "~/components/pages/store/StoreFilterMinimal";
export default {
    name: "StoreSubmenu",
    components: {StoreFilterMinimal},
    props: {
        submenuList: {
            type: Array,
            default: () => { return [] }
        }
    },
    data() {
        return {
            scrollLeft: 0,
            maxScroll: 0,
            sticky: false,
        }
    },
    computed: {
        canScrollLeft() {
            return this.maxScroll > 0 && this.scrollLeft < this.maxScroll ;
        },
        canScrollRight() {
            return this.maxScroll > 0 && this.scrollLeft > 0;
        },

    },
    methods: {
        calculateMaxScroll() {
            this.maxScroll = this.$refs.list.scrollWidth - this.$refs.list.clientWidth;
        },
        scrollToPoint(point){
            this.$refs.list.scrollTo({
                left: point,
                behavior: "smooth"
            });
        },
        scrollToLeft() {
            let delta = this.scrollLeft + this.$refs.list.clientWidth - 80;
            this.scrollToPoint(delta)
        },
        scrollToRight() {
            let delta = this.scrollLeft - this.$refs.list.clientWidth - 80;
            this.scrollToPoint(delta)
        },

        stickyScroll() {
            let scroll = window.scrollY,
                header = document.getElementsByClassName('header')[0].offsetHeight,
                headerContainer = document.getElementsByClassName('header__static-container')[0];

            let headerSticky = headerContainer.classList.contains('hide-header') ? 0 : headerContainer.offsetHeight;
            this.sticky = this.$refs.bar.getBoundingClientRect().top <= headerSticky + 15;
            this.$refs.panel.style.marginTop = this.sticky ? headerSticky+'px' : 0;
        }
    },
    mounted() {
        window.addEventListener('scroll', this.stickyScroll);
        this.$refs.list.addEventListener('scroll', function (e){
            this.scrollLeft=this.$refs.list.scrollLeft;
        }.bind(this))
        this.calculateMaxScroll();
    },
    beforeDestroy(){
        window.removeEventListener('scroll', this.stickyScroll);
    },

}
