//
//
//
//
//
//

import widgetLayoutMixin from "@/mixins/widgetLayoutMixin";
import YeezyCard from "./Components/yeezyCard";

export default {
    name: "Main",
    components: {YeezyCard},
    mixins: [widgetLayoutMixin],
}
