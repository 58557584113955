//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "YeezyFormDataCharterLayout",
    props: {
        title: {
            type: String,
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        }
    }
}
