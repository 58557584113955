//
//
//
//
//
//
//
//
//

import VPopup from "~/plugins/VPopup/popup";
export default {
    name: "YeezyConfirmSize",
    components: {VPopup},
    methods: {
        closePopup(){
            this.$popups.close();
            this.$router.push('/')
        }
    }
}
