import esEvent from '~/assets/js/eSputnik/esEvent';

export default function categoryPageEvent(data) {
    const eventName = 'CategoryPage';

    if (typeof data !== 'object' || !data.breadcrumbs.length) {
        console.warn('Wrong data type', eventName, data);
    }
    const lastLocalStorageEventKey = `${eventName}EventKey`;
    const eventKey = data.breadcrumbs?.[data.breadcrumbs.length - 1]?.label;
    const uniqueIdKey = data.pageTitle;

    if(!isDuplicateEvent()){
        generateDataEvent();
    }

    function isDuplicateEvent(){
        const lastEventKey = window.localStorage.getItem(lastLocalStorageEventKey);
        return lastEventKey === uniqueIdKey;
    }

    function generateDataEvent() {
        window.localStorage.setItem(lastLocalStorageEventKey, uniqueIdKey);

        const eventBody = {
            categoryKey: eventKey,
        };

        sendEvent({
            [eventName]: eventBody
        });
    }

    function sendEvent(eventBody) {
        esEvent(eventName, eventBody);
    }
}
