//
//
//
//
//
//

import FaqPopular from "@/components/pages/faq/FaqPopular";
import widgetLayoutMixin from "@/mixins/widgetLayoutMixin";
export default {
    name: "Main",
    components: {FaqPopular},
    mixins: [widgetLayoutMixin],
}
