//
//
//
//
//
//
//
//
//

import ResponsiveImage from "@/components/common/ResponsiveImage";
import SubscribeFormPart from "@/widgets/subscribeBlock/layouts/Components/SubscribeFormPart";
import widgetLayoutMixin from "@/mixins/widgetLayoutMixin";
export default {
    name: "SubscribeBlock",
    components: {
        SubscribeFormPart,
        ResponsiveImage
    },
    mixins: [widgetLayoutMixin]
}
