//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import breakpointsListMixin from '~/mixins/breakpointsList.mixin';
import {mapGetters} from "vuex";

export default {
    name: "StoreHead",
    props: {
        info: {
            type: Object,
            default: () => { return {} }
        }
    },
    mixins: [
        breakpointsListMixin,
    ],
    data() {
        return {
            showDescription: false
        }
    },
    computed: {
        ...mapGetters({
            pageStructure: "dynamicPage/getMicroPageStructure",
        }),
        isDesktop() {
            return this.laptopSmallUp
        },
        btnText() {
            return this.showDescription ? 'Згорнути' : 'Дивитись більше';
        },
        platform() {
            return process.browser ? navigator.platform : false
        },
        isMac() {
            return process.browser ? navigator.platform.indexOf('Mac') > -1 : false
        },
        catalogSubmenuWidget() {
            return this.pageStructure.find(w => w.name === "widget_submenu")
        },
        submenuList() {
            return  this.catalogSubmenuWidget?.data?.items ?? [];
        },
        hasCatalogSubmenu() {
            return  Boolean(this.submenuList?.length);
        }
    },
    methods: {
        toggleDescription() {
            this.showDescription = !this.showDescription;
        }
    }
}
