//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SlideCarousel from "@/components/common/slideCarousel/index";
import Item from "./Components/Item";
import widgetLayoutMixin from "../../../mixins/widgetLayoutMixin";
import CommonMedia from "@/components/common/CommonMedia";
import SectionContainer from "../../../components/common/section/SectionContainer";

export default {
    name: "Main",
    mixins: [widgetLayoutMixin],
    components: {
        SectionContainer,
        Item,
        SlideCarousel,
        CommonMedia
    }
}
