//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyFormRow from "@/components/common/LazyFormRow/index";
import CommonForm from "@/components/common/CommonForm";
import popupWithForm from "@/mixins/popupWithForm";
export default {
    name: "YeezyPhoneCode",
    components: {CommonForm, LazyFormRow},
    mixins: [popupWithForm],
    props: {
        pushToRegisterOnError:{
            type: Boolean,
            default: false
        }
    },
    methods: {
        successSubmit(response) {
            this.$emit('success', { phone: response.OtpVerifyCodeForm.phoneNumber, token: response.OtpVerifyCodeForm.token  })
        },
        onResend() {
            this.$emit('resendCode')
        },
    }
}
