//
//
//
//
//
//

import SubscribeForm from '@/widgets/subscribeBlock/layouts/Components/subscribeForm/index'

export default {
    name: "SubscribeFormPart",
    data() {
        return {
            formId: 'subscription_form'
        }
    },
    components: {
        SubscribeForm
    },
}
