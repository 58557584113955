//
//
//
//
//
//

import mainLayout from './layouts/Main';
import widgetMixin from "@/mixins/widgetMixin";
export default {
    name: "HomeSeo",
    components: {
        mainLayout
    },
    mixins: [widgetMixin],
}
