//
//
//
//
//
//
//
//
//
//
//
//

import YeezySlider from "./Components/YeezySlider";
import YeezyDescription from "./Components/YeezyDescription";
import YeezyInfo from "./Components/YeezyInfo";
import breakpointsListMixin from '~/mixins/breakpointsList.mixin';

export default {
    name: "YeezyContent",
    components: {YeezyInfo, YeezyDescription, YeezySlider},
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    mixins: [
        breakpointsListMixin,
    ],
    computed: {
        isDesktop() {
            return this.tabletLandscapeUp;
        }
    },
}
