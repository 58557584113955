//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FaqPopular from "@/components/pages/faq/FaqPopular";
import widgetLayoutMixin from "@/mixins/widgetLayoutMixin";
import Breadcrumbs from "@/components/common/Breadcrumbs";
import topArrow from '~/assets/img/icons/arrow-left.svg?raw'

export default {
    name: "Main",
    components: {Breadcrumbs, FaqPopular},
    mixins: [widgetLayoutMixin],
    data() {
        return {
            topArrow: topArrow,
        }
    },
    computed: {
        meta() {
            return {
                breadcrumb: [
                    {
                        url: '/help',
                        title: '',
                    },
                ]
            }
        }
    }
}
