//
//
//
//

import mainLayout from './layouts/Main'
import widgetMixin from "@/mixins/widgetMixin";

export default {
    name: "CompilationCatalog",
    components: {
        mainLayout,
    },
    mixins: [widgetMixin],
}
