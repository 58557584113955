//
//
//
//
//
//
//
//


import StoreHead from "@/components/pages/store/StoreHead/index";
import {mapGetters} from "vuex"

export default {
    name: "CatalogTitle",
    components: {
        StoreHead
    },
    computed: {
        ...mapGetters({
            pageH1: 'dynamicPage/pageH1',
            productsCount: 'catalogStore/productsCount',
        }),
        headInfo() {
            return {
                title: this.pageH1,
                totalCount: this.productsCount
            }
        }
    },
}
