//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "FaqPopular",
    props: {
        innerData: {
            type: Object,
            default: () => { return {} }
        },
        isH1Title: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        list() {
            return (this?.innerData?.items ?? [])
        }
    }
}
