import {mapGetters} from 'vuex'

export default {
    fetchOnServer: true,
    computed:{
        ...mapGetters({
            'getFormById': 'forms/getFormById'
        }),
        form () {
            return this.getFormById(this.formId)
        },
        fields () {
            return (this.form?.fields ?? [])
        },
        fieldsStates() {
            return [...((this?.fields ?? []).reduce((acc, item) => {
                !acc.has(item.formName) && acc.add(item.formName);
                return acc
            }, new Set()))]
        },
    },
    methods: {
        onInput(newFieldData) {
            this.$store.commit(`forms/${types.UPDATE_FORM_FIELD_BY_ID}`, {
                formId: this.formId,
                fieldName: newFieldData.name,
                newFieldData: newFieldData
            })
        }
    }
}
