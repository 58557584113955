//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import product_card from "@/widgets/store/ProductCard/ProductCard";
import catalog from "@/widgets/store/Catalog/Catalog.vue";
import widget_keyword_component from "@/widgets/storeSubcategories/Index";
import widget_main_page_banner from "@/widgets/mainHomeBanner/Index";
import widget_countdown_timer from "@/widgets/countdownTimer/Index";
import content_product_categories from "@/widgets/forWhom/Index";
import widget_visual_navigation from "@/widgets/categoriesTabs/Index";
import content_teaser_carousel from "@/widgets/compilationCarousel/Index";
import widget_teaser_card from "@/widgets/compilationCard/Index";
import widget_teaser_product_navigation from "@/widgets/teaserProductNavigation/index";
import breadcrumbs from "@/widgets/common/Breadcrumbs/Breadcrumbs";
import catalog_title from "@/widgets/store/Catalog/Title.vue";
import page_404 from "@/widgets/common/NotFound/NotFound";
import widget_bestsellers from "@/widgets/productCarousel/Index";
import widget_novelty from "@/widgets/productCarousel/Index";
import widget_we_recommend from "@/widgets/productCarousel/Index";
import widget_recently_viewed from "@/widgets/productCarousel/Index";
import widget_choice_other_buyers from "@/widgets/productCarousel/Index";
import content_teaser_small from "@/widgets/teaserCardSmall/Index";
import widget_keyword_component_main from "@/widgets/storeSubcategories/Index";
import widget_text from "@/widgets/HomeSeo/index";
import widget_items_list from "@/widgets/itemsList/index";
import widget_help_post from "@/widgets/helpPost/index";
import widget_stay_tuned from "@/widgets/subscribeBlock/index";
import widget_teaser_card_two_cards from "@/widgets/compilationCardTwo/Index";
import widget_pdl_teaser from "@/widgets/compilationCatalog/index";
import widget_video from "@/widgets/video/index";
import loader from "@/components/common/DynamicPageLoader.vue";
import widget_yeezy from "../widgets/yeezy/index";
import widget_image_collection from "@/widgets/imageCollection/Index";

if (process.client) {
    window.addEventListener("popstate", (e) => {
        window.$popstateChanged = true;
    });
}

export default {
    name: "DynamicPage",

    components: {
        product_card,                   // Виджет "Карточка товара"
        catalog,                        // Виджет Каталога
        widget_keyword_component,       // Виджет "Поппулярне зараз"
        widget_main_page_banner,        // Задача "Основной баннер"
        widget_countdown_timer,         // Виджет "Countdown"
        content_product_categories,     // Задача "Виджет подборок"
        widget_visual_navigation,       // Задача "Виджет "Для кого выбираешь?""
        content_teaser_carousel,        // Задача "Карусель подборок"
        widget_teaser_card,             // "Виджет подборок (Teaser Card)"
        widget_teaser_product_navigation,// "Виджет подборок товаров (Teaser Product Card)"
        breadcrumbs,                    // хлебные крошки
        catalog_title,                  // каталог основной
        page_404,                       // каталог основной
        widget_bestsellers,             // Виджет "хиты продаж"
        widget_novelty,                 // Виджет "Новинки"
        widget_we_recommend,            // Виджет "Мы рекомендуем"
        widget_recently_viewed,         // Виджет "недавно просмотренные"
        widget_choice_other_buyers,     // Виджет "Выбор других покупателей"
        content_teaser_small,           // Виджет "Teaser card small" https://imgur.com/xRZScyE
        widget_keyword_component_main,  // Виджет "Популярно сейчас для главной"
        widget_text,                    // Виджет текстовый
        widget_items_list,              // Виджет "НАЙПОПУЛЯРНІШІ ПИТАННЯ"
        widget_help_post,               // Отрисовка поста из раздела "Помощь"
        widget_stay_tuned,              // Виджет подписка
        widget_teaser_card_two_cards,   // "Виджет подборок (Teaser Card)"
        widget_pdl_teaser,              // "Виджет каталог подборок"
        widget_video,                   // "Виджет видео блок"
        loader,                         // Loader
        widget_yeezy,                   // Yeezy widget
        widget_image_collection,        // widget_image_collection
    },

    computed: {
        ...mapGetters({
            pageType: 'dynamicPage/pageType',
            routeInfo: 'dynamicPage/getRouteInfo',
            macroPageStructure: 'dynamicPage/getMacroPageStructure',
            microPageStructure: 'dynamicPage/getMicroPageStructure',
            pageLoading: 'dynamicPage/getDynamicPageLoading'
        }),
        filteredPageStructure () {
            return this.sortedPageStructureWithData.filter((block)=>{
                const componentForWidgetExist = Object.prototype.hasOwnProperty.call(this.$options.components, block.name);
                const isWidget = (block?.type ?? 'widget') === 'widget';
                let canBeShowen = componentForWidgetExist;
                if (isWidget){
                    const dataFilled = !Array.isArray((block?.data ?? [])) || Boolean(block?.data?.length);
                    canBeShowen = canBeShowen && dataFilled
                    if (this.pageType === 'pdp') {
                        canBeShowen = false
                    }
                }
                return canBeShowen
            })
        },
        sortedPageStructureWithData() {
            return ([].concat(this.microPageStructure)).sort(function (a, b) {
                return a.orderOnPage - b.orderOnPage
            })
        },
        nowRoute() {
            return this.$route.name
        },
        showLoading() {
            return !this.filteredPageStructure.length || this.pageLoading
        },
    },

    methods:{
        scrollToElement(productItem) {
            this.$nextTick(()=>{
                productItem.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
            })
            this.clearScrollToProductLog();
        },
        clearScrollToProductLog(){
            window.sessionStorage.removeItem("elementToScroll");
            window.$popstateChanged = false;
        }
    },

    mounted() {
        if (process.client) {
            const elementToScrollId = window.sessionStorage.getItem("elementToScroll");
            if(window.$popstateChanged && elementToScrollId ){
                const productItem = document.querySelector(`[data-product-id="${ elementToScrollId }"]`);
                if(productItem){
                    this.scrollToElement(productItem);
                }else {
                    this.clearScrollToProductLog();
                }
            }
        }

    },

    beforeRouteLeave(to, from, next) {
        window.scrollTo(0,0);
        this.$store.commit(`dynamicPage/${types.CLEAR_PAGE_INFO}`, null)
        next()
    }
}
