//
//
//
//
//
//
//
//
//
//

export default {
    name: "YeezyBackButton",
    methods: {
        goBack(){
            this.$router.back()
        }
    }
}
