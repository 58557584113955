//
//
//
//
//

export default {
    name: "YeezyDescription",
    props: {
        description: {
            type: String,
            required: true
        }
    }
}
