//
//
//
//
//
//
//
//

import productCard from "@/components/pages/productCard/index";
import { mapGetters } from "vuex";
import productStructuredData from "~/mixins/structuredData/productStructuredData";

export default {
    name: "ProductCard",

    head() {
        return {
            script: [
                this.productStructuredData,
            ]
        };
    },

    components: {
        productCard
    },

    mixins: [
        productStructuredData
    ],

    computed: {
        ...mapGetters({
            "productData": "productView/getProduct"
        }),
    },
};
