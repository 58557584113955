//
//
//
//

import SubscribeForm from "@/widgets/subscribeBlock/layouts/Components/subscribeForm/Components/SubscribeForm";
import SubscribeAgree from "@/widgets/subscribeBlock/layouts/Components/subscribeForm/Components/SubscribeAgree";
import {mapGetters} from "vuex";
export default {
    name: "SubscribePopup",
    components: {SubscribeForm, SubscribeAgree},
    props: {
        formId: {
            type: String,
            default: ''
        },
        source: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            step: 1,
            message: '',
            email: ''
        }
    },
    computed: {
        ...mapGetters({
            isSubscribed: 'user/isSubscribed',
        }),
        SubscribeComponent() {
            switch (this.step) {
                case 2:
                    return 'SubscribeAgree';
                default:
                    return 'SubscribeForm'
            }
        },
    },
    methods: {
        changeStep(data) {
            this.step = data.step;
            this.message = data.message
            this.email = data.email
        }
    },
    mounted() {
        if(!process.browser) {
            return false
        }
    }
}
