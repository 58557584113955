//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import StorePaginationSelect from "@/components/pages/store/StorePagination/Components/StorePaginationSelect";
import {mapGetters} from 'vuex';
import _set from '~/utils/_set';
import breakpointsListMixin from '~/mixins/breakpointsList.mixin';

export default {
    name: "StorePagination",
    components: {StorePaginationSelect},
    props: {},
    mixins: [
        breakpointsListMixin,
    ],
    data() {
        return {}
    },
    computed: {
        isDesktop() {
            return this.laptopSmallUp;
        },
        ...mapGetters({
            pageCountsInfo: 'catalogStore/pageCountsInfo',
            firsPageLink: 'catalogStore/firsPageLink',
        }),
        showPagination() {
            return !!this.pageCountsInfo && this.lastPageNumber > 1
        },
        activePage() {
            return (this.pageCountsInfo?.currentPage ?? 1)
        },
        showPrevBtn() {
            return (this.pageCountsInfo?.currentPage ?? 1) > 1
        },
        currentPageNumber() {
            return (this.pageCountsInfo?.currentPage ?? 1)
        },
        lastPageNumber() {
            return (this.pageCountsInfo?.pageCount ?? 1)
        },
        prevLinkObj() {
            const pageNumber = this.currentPageNumber > 1 ? this.currentPageNumber - 1 : this.currentPageNumber
            return this.createUrlObjForPage(pageNumber)
        },
        nextLinkObj() {
            return this.createUrlObjForPage(this.currentPageNumber + 1)
        },
        showNextBtn() {
            return (this.pageCountsInfo?.currentPage ?? 1) < (this.pageCountsInfo?.pageCount ?? 1)
        },
        linksForDropdown() {
            const res = [];

            for (let i = 1; i <= this.lastPageNumber; i++) {
                res.push({
                    urlObj: this.createUrlObjForPage(i),
                    title: i
                })
            }
            return res
        },
    },
    methods: {
        createUrlObjForPage(page) {
            const queryParams = {
                ...this.$route.query
            };
            let link = this.firsPageLink;
            queryParams.hasOwnProperty('page') && page < 2 && (delete queryParams.page);

            page > 1 && _set(queryParams, 'page', page);

            // link += this.serializeQuery(queryParams);

            return {
                url: link,
                query: queryParams
            };
        },
        serializeQuery(queryObject) {
            return Helpers.serializeQuery(queryObject)
        }
    },

}
